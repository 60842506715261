export const  MODE = process.env.REACT_APP_MODE || 'MAINNET';

export const EXPLORER = {
    TRX: {
        MAINNET:'https://tronscan.org',
        TESTNET:'https://nile.tronscan.org'
    },
    BSC:{
        MAINNET:'https://bscscan.com',
        TESTNET:'https://testnet.bscscan.com'
    },
    ETH:{
        MAINNET:'https://etherscan.io',
        TESTNET:'https://ropsten.etherscan.io'
    },
    KCC:{
        MAINNET:'https://explorer.kcc.io',
        TESTNET:'https://scan-testnet.kcc.network'  
    },
    VELAS:{
        MAINNET:'https://evmexplorer.velas.com',
        TESTNET:'https://evmexplorer.testnet.velas.com'    
    }
}

export const BRIDGE_07_SMART_CONTRACT = {
    BSC_NETWORK:{
        MAINNET:'0x8ED6b90B22619dC13a12227c9B2B086807eCbE7A', //product
        TESTNET:'' //testnet
    }
}

export const BACKEND_URI = {
    // MAINNET:'https://bridge.tronpad.network',   //
    MAINNET:'https://bridge.velaspad.io',  //https://bridge.tronpad.network
    TESTNET:'http://bridge-velaspad.myfantasy.cloud'
}

export const IMAGE_URL = 'https://bspcpad.s3.ap-southeast-1.amazonaws.com/'


export const SWAP_SMART_CONTRACT = {
    BSC_NETWORK:{
        MAINNET:'0x103db4074aEDf21152258f84049eD2275E2FC9Ad', //product
        TESTNET:'0x6E09c3703D510bf758Fd2D2100947d3d8EA66DC9' //testnet 
    },
   
    TRON_NETWORK:{
        MAINNET:'TTod5T5MgechYpRnTPDm54b4x42LWqsYCB', //product
        TESTNET:'TYsNsZ3zFb7ivyyNuT9ktwSGLATxzqHsM5'  //testnet
    },
    ETH_NETWORK:{
        MAINNET:'0x5880a0E3a5716938fB956849187A0Cf8Ffc9c532', //product
        TESTNET:'0x4018Ad87e0702Ae3510d753Ad34449e6D2D9c1dA' //testnet
    },
    KCC_NETWORK:{
        MAINNET:'0x484277B05924d10F9d8ee8b342f4330F05BBb6be', //product
        TESTNET:'0xB4562dF8D3cD487Fe82E0bC0Cb2742ec6De566aF' //testnet   
    },
    VELAS_NETWORK:{
        MAINNET:'0xc3dB2DFF83f33Df96446B8B7a607792656052ABB',
        TESTNET:'0x2C5380c1d1E5CD0EE4E7101b604463Bc67225623' //testnet     
    }
}

export const RPC = {
  BSC_NETWORK:{
      MAINNET:'https://bsc-dataseed.binance.org/',
      TESTNET:'https://data-seed-prebsc-1-s1.binance.org:8545/'
  },
  ETH_NETWORK:{
      MAINNET:process.env.REACT_APP_NODE_INFURA_URI,
      TESTNET:process.env.REACT_APP_NODE_INFURA_URI
  },
  VLX_NETWORK:{
    MAINNET:'https://mainnet.velas.com/rpc',
    TESTNET:'https://testnet.velas.com/rpc' 
  }
}