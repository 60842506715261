import React from 'react';

export default function AssetSelected({ token }) {
    return (
        <div className="p-form-group-wrap">
            <label className="font-14 mb-2 text-warning">Asset</label>
            <div className="p-form-group mb-3">
                <div className="p-form-group-content">
                    <div className="p-input-group p-select-asset justify-content-between" data-bs-toggle="modal" data-bs-target="#selectAssetModal">
                        <div className="d-flex align-items-center">
                            <img height="18" src={token.image} />
                            <span className="ms-2 p-main-text">{token.symbol}</span>
                        </div>
                        <a href="#" className="btn btn-sm btn-select-network opacity-08">
                            <i className="mdi mdi-chevron-right font-20"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
