import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST } from "../../constants";
import { get } from "lodash";
import useCopyToClipboard from './CopyToClibboard';
import { useWalletAddress } from '../hook/useState';


const WalletModal = (props) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const walletAddress = useWalletAddress();
    const handleLogout = () => {
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })

        window.location.reload();
    }

    return (
        <>
            <div className="modal fade " id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="walletModalLabel">Your wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 text-center">
                                <h5 style={{ fontSize: '20px',lineHeight:1.5, wordBreak: 'break-word' }} className="text-light mb-2 w-100 text-center">
                                    <b>{walletAddress}</b>
                                </h5>
                            </div>
                            <div className="text-center mt-4 d-flex align-items-center justify-content-center mb-3">
                                <div className="button-container">
                                    <a className="button" href="#" onClick={(e) => { e.preventDefault(); setCopied(walletAddress) }}>
                                        <span><i className={`mdi me-2 ${!copied ? 'mdi-content-copy' : 'mdi mdi-check'}`} style={{ lineHeight: 1, fontSize: '14px' }}></i>Copy Address</span>
                                    </a>
                                </div>
                                <div className="button-container button-container-danger ms-3">
                                    <a className="button button-danger" href="#" onClick={handleLogout} data-bs-dismiss="modal">
                                        <span>Disconnect</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


