import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import packageJson from '../../../../package.json';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-white-50 mt-md-0 mt-3 text-md-start text-center">Copyright © {currentYear}. All Rights Reserved by VELASPAD</div>
            <div className="col-md-4 text-center text-white-50">Version {packageJson.version}</div>
            <div className="col-md-4">
              {/* <a className="text-warning text-decoration-none d-flex align-items-center justify-content-md-end justify-content-center" href="mailto:support@velaspad.io">
                <i className="mdi mdi-at me-1 font-20"></i>
                <span>Customer Support</span>
              </a> */}
              <ul className="footer-menu">
                <li><Link to={ROUTES.PRIVACY_POLICY} target="_blank">Privacy Policy</Link></li>
                <li><Link to={ROUTES.COOKIES_POLICY} target="_blank">Cookies Policy</Link></li>
                <li><Link to={ROUTES.TERMS_OF_USE} target="_blank">Terms &amp; Conditions</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />

      <WalletModal />

    </>
  );
}