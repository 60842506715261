import window from "global";
import { BigNumber } from "bignumber.js";
import { NETWORK_LIST } from "../constants";

export function isMetamaskAvailable() {
  return !!(window.ethereum && window.ethereum.isMetaMask);
}

export function isBinanceExtensionAvailable() {
  return !!window.BinanceChain;
}

export function isTrustWalletAvailable() {
  return !!(window.ethereum && window.ethereum.isTrust);
}

export function isTronLinkAvailable() {
  return !!window.tronWeb;
}

export function retryWithTimeout(
  callback,
  callbackReject = function () {},
  retryTime = 5,
  timeout = 1000
) {
  return setTimeout(async () => {
    try {
      await callback();
    } catch (e) {
      // console.log(e.message)
      if (retryTime === 0) {
        console.error(e.message);
        await callbackReject();
      } else {
        return retryWithTimeout(callback, retryTime - 1, timeout);
      }
    }
  }, timeout);
}
export function calculateBalanceSend(balance) {
  return BigNumber(balance)
    .multipliedBy(10 ** 18)
    .integerValue();
}

export function calculateBalanceBigNumber(balance, decimal) {
  return BigNumber(balance)
    .multipliedBy(10 ** decimal)
    .integerValue();
}

export function calculateBalanceBigNumberTron(balance, decimal) {
  return BigNumber(balance)
    .multipliedBy(10 ** decimal)
    .integerValue()
    .toFixed();
}

export const isWeb3NetWorkSupport = (networkId) => {
  const arr = NETWORK_LIST.map((e) => {
    if (e.type === "web3") {
      return e.id;
    }
  });
  return !(arr.indexOf(networkId) === -1);
};
