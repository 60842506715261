
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import BlockUi from "react-block-ui"
import "react-block-ui/style.css";
import { get } from 'lodash';
import { ACTION_CONST, MESSAGES, STATUS } from '../../constants';
import { MODE, SWAP_SMART_CONTRACT, EXPLORER } from '../../_configs';
import { CHAIN_IDS } from '../../constants';
import { getStatusSwap } from '../../redux/services/bridge.api';
import SwapLineStepTab from './SwapLineStepTab';
import SwapStepContent from './SwapStepContentTab';
import { useShowBlockUI, useWeb3Utils } from '../../component/hook/useState';


const StepModal = ({ amount, token, inputNetwork, outputNetwork, destination, clearAmount, handleSwapped }) => {
    const dispatch = useDispatch();

    const showBlockUI = useShowBlockUI()

    const walletUtils = useWeb3Utils()


    const [currentStep, setCurrentStep] = useState(0);
    const [step, setStep] = useState(1);
    const [receiptHash, setReceiptHash] = useState("");
    const [status, setStatus] = useState("pending");






    //handle approve button
    const handleApproveStep = async () => {

        const bridgeNetwork = `${inputNetwork.id}_NETWORK`.toLocaleUpperCase();
       
        if (!walletUtils) return;
        
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });

        
        const allowance = await walletUtils.getAllowance(token.contractAddress, token.bridgeContractAddress);
        if (Number(allowance) >= Number(amount)) {
            setStep(3);
            setCurrentStep(2);
            dispatch({
                type: ACTION_CONST.REQUEST_DONE,
            });
            return;
        }
        // console.log(SWAP_SMART_CONTRACT[bridgeNetwork][MODE]);
        // debugger
        walletUtils.approveToken(
            {
                tokenContractAddress: token.contractAddress,
                spenderAddress: token.bridgeContractAddress,
                amount: Number(amount),
                inputNetWork: inputNetwork.id,
                decimal: token['decimals']
            },
            (data) => {
                if (data.status === STATUS.APPROVED) {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: MESSAGES.APPROVE_ASSET_SUCCESS,
                    });
                    setStep(3)
                    setCurrentStep(2)
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                }
                if (data.status === STATUS.APPROVE_FAILS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: MESSAGES.APPROVE_ASSET_FAIL,
                    });
                }
            }
        );
    }

    const handleSwapClickStep = () => {
        if (!walletUtils) return;

        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });


        // switch (bridgeNetwork) {
        //     case "BSC_NETWORK":
       
        walletUtils.swap(
            {
                amount: Number(amount),
                tokenAddress: token.contractAddress,
                toAddress: destination,
                decimal: token['decimals'],
                fee: token.fee,
                desNetwork: token.networks,
                bridgeContractAddress: token.bridgeContractAddress,
            },
            (result) => {
                if (result.status === STATUS.SWAP_SUCCESS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });

                    //set value 0 

                    setStep(4)
                    setCurrentStep(3)
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });

                    handleSwapped(token.contractAddress)
                    getStatus(token.srcNetwork || inputNetwork.id, token.networks, result.txID);

                    let alertLink = "";
                    if (inputNetwork.id === 'TRON') {
                        alertLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/#/transaction/${result.txID}`
                    } if (inputNetwork.id === 'BSC') {
                        alertLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'ETH') {
                        alertLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'KCC') {
                        alertLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    } 
                    if (inputNetwork.id === 'VLX') {
                        alertLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    //alert notify with link
                    dispatch({

                        type: ACTION_CONST.ALERT_LINK,
                        alertType: "success",
                        alertUrl: alertLink,
                        alertMessage: `Swap successfully with txID ${result.txID}`,
                    })
                }
                if (result.status === STATUS.SWAP_FAILS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: MESSAGES.SWAP_FAIL,
                    });
                }
            }
        );
    }

    //get status button
    const getStatus = (srNetwork, desNetwork, txID) => {
        const job = setInterval(() => {
            getStatusSwap(srNetwork,desNetwork, txID).then(data => {

                if (data) {
                    // console.log(data);
                    setReceiptHash(data["receiptHash"]);
                    setStatus(data["status"].toLowerCase());
                    if (data["status"].toLowerCase() === "completed" || data["status"].toLowerCase() === "fail") {

                        setCurrentStep(4);
                        clearInterval(job);
                    }
                }
            })
        }, 10 * 1000)
    }

    const handleClear = () => {
        setCurrentStep(0);
        setStep(1);
        setStatus("pending");
        setReceiptHash("")
        clearAmount();
    }

    return (
        <>

            <div className="modal fade" id="stepModal" tabIndex="-1" aria-labelledby="stepModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title w-100 text-center" id="stepModalLabel">BRIDGE PROCESS</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <BlockUi tag="div" blocking={showBlockUI} className="block-ui-step-modal">
                            <div className="modal-body pt-4 pb-0 px-3">
                                <div className="bs-stepper w-100">
                                    <SwapLineStepTab step={step} currentStep={currentStep} status={status} />
                                    <SwapStepContent status={status}
                                        step={step} amount={amount} token={token} receiptHash={receiptHash}
                                        inputNetwork={inputNetwork} outputNetwork={outputNetwork} />

                                </div>
                            </div>
                            <div className="modal-footer justify-content-center py-4" style={{ border: 0 }}>
                               
                                {
                                    step === 1 && <div className="button-container">
                                    <button type="button" className="button"
                                        onClick={() => { setStep(2); setCurrentStep(1) }}
                                    >
                                        <span>Confirm</span>
                                    </button>
                                    </div>
                                }
                                {
                                    step === 2 &&
                                    <div className="button-container">
                                    <button type="button" className="button"
                                        onClick={handleApproveStep} >
                                        <span>Approve</span>
                                    </button>
                                    </div>
                                }
                                {
                                    step === 3 &&
                                    <div className="button-container">
                                    <button type="button" className="button"
                                        onClick={async () => handleSwapClickStep()}
                                    >
                                        <span>Submit</span>
                                    </button>
                                    </div>
                                }
                                {
                                    currentStep === 4 &&
                                    <div className="button-container">
                                    <button type="button" className="button" data-bs-dismiss="modal"
                                        onClick={handleClear}
                                    >
                                        <span>Done</span>
                                    </button>
                                    </div>
                                }
                            </div>
                        </BlockUi>
                    </div>
                </div>

            </div>

        </>
    )
}
export default StepModal;