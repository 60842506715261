import React from 'react';

export default function CardBody({ title, children }) {
    return (
        <>
            <div className="card-body" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                <h5 className="mb-3 d-flex align-items-center justify-content-center text-uppercase text-center">{title}</h5>
                {children}
            </div>
        </>
    );
}
