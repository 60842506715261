import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES, } from "../../../constants";
import { helpers } from "../../../utils/helpers";
import { useIsConnected, useShowModalHelp, useWalletAddress, useWeb3Utils } from "../../hook/useState";

const Header = (props) => {

  const dispatch = useDispatch();
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const isConnectWallet = useIsConnected();
  const showModalHelp = useShowModalHelp();
  const walletAddress = useWalletAddress();
  const walletUtils = useWeb3Utils();


  // const [init, setInit] = useState(true);

  const [amountBalance, setAmountBalance] = useState(0)



  useEffect(() => {

    if (walletUtils) {


      walletUtils.getBalanceAccount().then(balance => {
        setAmountBalance(balance)

      }).catch(err => console.log(err));

      // setInit(false)
      const job = setInterval(() => {

        walletUtils.getBalanceAccount().then(balance => {
          setAmountBalance(balance)

        }).catch(err => console.log(err));


      }, 10 * 1000);
      // set reducer getbalance
      dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: job })



    }
  }, [walletUtils])

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({
      type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
    })

    window.location.reload();
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="https://velaspad.io/" target="_blank">
            <img src="/images/logo.svg" height="54" alt="VELASPAD" className="me-2" />
          </a>
          {isMobile() &&
            <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
              <div className="dropdown  d-block d-md-none d-lg-none d-xl-none">
              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"

                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

            </ul>
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected align-items-center' : 'navbar-nav ms-auto mb-2 mb-md-0 align-items-center'}>
              <li className="nav-item me-5">
                <a href={`https://app.velaspad.io/staking`} target="blank" className="nav-link">
                  <span>STAKING</span>
                </a>
              </li>
              {/* <li className="nav-item me-5">
                <NavLink activeClassName="active" className="nav-link" to={ROUTES.BRIDGE} >
                  <span><b>BRIDGE</b></span>
                </NavLink>
              </li> */}
              {
                !isConnectWallet ?
                  <li className="nav-item me-2 li-connect">
                    <div className="button-container">
                      <a className="button" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2 mb-2 mb-md-0">
                      <div className="button-container button-container-success">
                        <button type="button d-flex flex-nowrap text-nowrap" className="button button-success" style={{
                          paddingLeft: '20px',
                          paddingRight: '20px'
                        }}>
                          <span>{`${amountBalance}`}</span>
                        </button>
                      </div>
                    </li>
                    <li className="nav-item me-2">
                      <div className="button-container">
                        <a className="button d-flex flex-nowrap text-nowrap" href="#" data-bs-toggle="modal" data-bs-target="#walletModal" style={{
                          paddingLeft: '20px',
                          paddingRight: '20px'
                        }}>
                          <span><i className="mdi mdi-wallet-plus-outline me-2" style={{ fontSize: '14px', lineHeight: 1 }}></i>{helpers.formatTransactionHash(walletAddress, 8, 8)}</span>
                        </a>
                      </div>
                    </li>
                    <li className="nav-item d-none d-md-block">
                      <div className="button-container button-container-danger">
                        <a className="button d-flex flex-nowrap text-nowrap button-danger" href="#" onClick={(e) => handleLogout(e)} style={{
                          paddingLeft: '20px',
                          paddingRight: '20px'
                        }}>
                          <span><i className="mdi mdi-logout-variant me-2" style={{ fontSize: '14px', lineHeight: 1 }}></i>Disconnect</span>
                        </a>
                      </div>
                    </li>
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
