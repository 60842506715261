import React, { useEffect, useState } from 'react';
import AssetSelected from '../AssetSelected';
import AssetSelectModal from '../AssetSelectModal';

export default function AssetPanel({ onChangeToken, tokenSelected, tokens }) {
    const [token, setToken] = useState({});

    useEffect(() => {
        if (tokens.length > 0) {
            if (tokenSelected.symbol) {
                setToken(tokenSelected)
            } else {
                setToken(tokens[0])
            }
        }
    }, [tokens, tokenSelected])
    return (
        <>
            <AssetSelected token={token} />
            <AssetSelectModal onClickSelect={onChangeToken} listTokens={tokens} selected={token.symbol} />
        </>
    );
}
